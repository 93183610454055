<template>
  <div class="akt-detail">
    <div class="columns no-margin">
      <div class="column no-padding is-full">
        <div class="card">
          <header class="card-header has-background-primary">
            <div @click.stop="$router.go(-1)" class="card-header-icon">
              <b-icon
                class="has-text-light"
                size="is-small"
                icon="arrow-left"
              />
            </div>
            <p class="card-header-title has-text-light">PENCAPAIAN NILAI</p>
          </header>
          <div class="card-content events-card">
            <div class="columns is-mobile">
              <div class="column detail has-text-weight-semibold is-4">
                Angkatan
              </div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">
                {{ stase.angkatan }}
              </div>
            </div>
            <div v-if="stase.mata_kuliah" class="columns is-mobile">
              <div class="column detail has-text-weight-semibold is-4">
                Mata Kuliah
              </div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">
                {{ stase.mata_kuliah.nama }}
              </div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">
                Periode
              </div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">
                {{ stase.mulai }} - {{ stase.hingga }}
              </div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">
                Penanggung Jawab
              </div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">
                <template v-if="stase.preceptor">{{
                  stase.preceptor.nama
                }}</template>
              </div>
            </div>
            <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
          </div>
        </div>
      </div>
    </div>

    <div class="columns pengampu-container">
      <div class="column is-full">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Dosen Pengampu</p>
          </header>
          <div class="content card-content">
            <div class="columns is-mobile">
              <div class="column">
                <b-field class="pengampu-field" grouped group-multiline>
                  <div
                    v-for="row in stase.stasepengampu_set"
                    :key="row.id"
                    class="tag-control"
                  >
                    <b-tag class="is-grey-res">{{ row.preceptor }}</b-tag>
                  </div>
                </b-field>
                <div
                  v-if="
                    stase.stasepengampu_set &&
                      stase.stasepengampu_set.length == 0
                  "
                  class="content has-text-grey has-text-centered"
                >
                  <p>Belum ada dosen pengampu yang terdaftar.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-full">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Nilai Mata Kuliah</p>
            <a
              v-if="canUploadNilai && stase.template"
              title="Download Template"
              class="is-pulled-right card-header-icon"
              :href="stase.template"
              target="_blank"
              download
            >
              <b-icon icon="download" pack="fas" />
            </a>
            <b-upload
              v-if="canUploadNilai"
              :key="`nilai_${fileCounter}`"
              v-model="flNilai"
              @input="() => uploadNilai(stase)"
              accept=".xlsx"
              class=" card-header-icon"
            >
              <a title="Upload Nilai">
                <b-icon icon="upload"></b-icon>
              </a>
            </b-upload>
          </header>
          <div class="card-content table-card-content">
            <b-table
              striped
              hoverable
              :data="stase.dokumennilai_set"
              :mobile-cards="false"
            >
              <b-table-column field="waktu" label="Waktu" v-slot="props">{{
                props.row.updated
              }}</b-table-column>
              <b-table-column field="dok_nilai" label="File" v-slot="props">
                <a :href="props.row.dok_nilai" target="_blank" download>{{
                  getFilename(props.row.dok_nilai)
                }}</a>
              </b-table-column>
              <b-table-column
                field="dosen"
                label="Dosen Pengampu"
                v-slot="props"
                >{{ props.row.preceptor }}
              </b-table-column>

              <template slot="empty">
                <div class="content has-text-grey has-text-centered">
                  <p>Tidak ada lampiran nilai.</p>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-full">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Nilai Akhir</p>
            <a
              v-if="canUploadNA"
              title="Download Template Nilai"
              class="card-header-icon"
              @click.stop.prevent="download"
            >
              <b-icon icon="download" pack="fas" />
            </a>
            <b-upload
              v-if="canUploadNA"
              :key="`nilaiakhir_${fileCounter}`"
              class="card-header-icon"
              v-model="flNilaiAkhir"
              @input="() => uploadNilaiAkhir(stase)"
              accept=".xlsx"
            >
              <a title="Upload Nilai">
                <b-icon icon="upload"></b-icon>
              </a>
            </b-upload>
          </header>
          <div class="card-content table-card-content">
            <b-table
              striped
              hoverable
              :data="stase.dokumennilaiakhir_set"
              :mobile-cards="false"
            >
              <b-table-column field="waktu" label="Waktu" v-slot="props">{{
                props.row.updated
              }}</b-table-column>
              <b-table-column
                field="dok_nilai_akhir"
                label="File"
                v-slot="props"
              >
                <a :href="props.row.dok_nilai_akhir" target="_blank" download>{{
                  getFilename(props.row.dok_nilai_akhir)
                }}</a>
              </b-table-column>
              <b-table-column field="dosen" label="Penanggung Jawab"
                >{{ stase.preceptor.nama }}
              </b-table-column>

              <template slot="empty">
                <div class="content has-text-grey has-text-centered">
                  <p>Tidak ada lampiran nilai.</p>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-full">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Mahasiswa</p>
          </header>
          <penilaian-table v-if="isLoaded" :stase="stase"></penilaian-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import fetchCreatedMixin from "@/apps/core/mixins/fetchCreatedMixin";
import deleteMixin from "@/apps/core/mixins/deleteMixin";
import Stase from "../models/pencNilai.js";
import APP_CONFIG from "@/apps/core/modules/config";
import NilaiExcel from "../modules/nilaiExcel";

export default {
  name: "StaseDetail",
  props: ["title"],
  mixins: [onlineCheckMixin, fetchCreatedMixin, deleteMixin],
  data() {
    this.staseMdl = new Stase();
    this.objectMdl = this.staseMdl;
    this.nilaiExcel = new NilaiExcel((dokNilai) => {
      if (dokNilai.dok_nilai) {
        this.stase.dokumennilai_set.push(dokNilai);
      } else {
        window.location.reload();
        // this.stase.dokumennilaiakhir_set.push(dokNilai);
      }
      this.flNilai = null;
      this.flNilaiAkhir = null;
    });
    let obv = this.staseMdl.getObservables();
    obv.flNilai = null;
    obv.flNilaiAkhir = null;
    obv.fileCounter = 0;
    return obv;
  },
  components: {
    PenilaianTable: () => import("@/apps/stase/components/PenilaianTable.vue"),
  },
  computed: {
    canUploadNA() {
      return this.isLoaded && this.stase.extra_data.can_upload_na;
    },
    canUploadNilai() {
      return this.isLoaded && this.stase.extra_data.can_upload_nilai;
    },
  },
  methods: {
    fetchData() {
      this.staseMdl.load(this.$route.params.id);
    },
    getFilename(url) {
      const arr = url.split("/");
      return decodeURI(arr[arr.length - 1]);
    },
    download() {
      this.nilaiExcel.setStase(this.stase);
      this.nilaiExcel.exportFile();
    },
    uploadNilai() {
      const apiUrl = `${APP_CONFIG.baseAPIURL}/stase/dokumennilai/`;
      this.nilaiExcel.setStase(this.stase);
      this.nilaiExcel.setApiUrl(apiUrl);
      this.nilaiExcel.uploadNilai(this.flNilai);
      this.fileCounter++;
    },
    uploadNilaiAkhir() {
      const apiUrl = `${APP_CONFIG.baseAPIURL}/stase/${this.stase.id}/pencapaian/`;
      this.nilaiExcel.setStase(this.stase);
      this.nilaiExcel.setApiUrl(apiUrl);
      this.nilaiExcel.uploadNilaiAkhir(this.flNilaiAkhir);
      this.fileCounter++;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/apps/core/assets/scss/detailview.scss";
@media screen and (max-width: 768px) {
  .card-content {
    font-size: 0.9rem !important;
    padding: 0.5rem;
  }

  .columns {
    margin-right: unset !important;
  }
}

::v-deep .is-grouped-multiline {
  margin-bottom: unset !important;
}

.icon {
  vertical-align: middle;
}

.pengampu-container {
  margin-top: 0.5rem;
}

div.table-card-content {
  overflow-x: auto;
  padding: 0 !important;
}

.is-grey-res {
  color: white !important;
  background-color: #6d6d6d !important;
}
</style>
