/* eslint no-unused-vars: "error" */
// Digunakan oleh Delete Views
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import { DialogProgrammatic as Dialog } from "buefy";


export default {
  mixins: [onlineCheckMixin],
  methods: {
    confirmDelete() {
      Dialog.confirm({
        title: "Menghapus Data",
        message: `Anda yakin akan <b>menghapus</b> ${this.title.toLowerCase()} ini?`,
        confirmText: `Hapus ${this.title}`,
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.objectMdl.delete(this.$route.params.id, () => 
            this.$router.go(-1)
          );
        }
      });
    }
  }
}
